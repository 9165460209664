<template>
  <div>
    <section class="section-top progress">
      <h1 class="section-top-title">服務條款</h1>
    </section>
    <section class="section-terms section">
      <div class="section-content">
        <article>
          <p>
            U-POWER
            超高速充電站為旭電馳科研股份有限公司所提供的電動車充電服務。您在開始使用
            U-POWER 服務前請充分、仔細閱讀以下使用條款與條件。當您開始使用
            U-POWER
            服務時，即表示您已同意本條款的內容。若您不同意本條款全部或任一部份內容，請勿使用本服務。U-POWER
            充電站的介紹與服務條款如下：
          </p>
          <h3 id="使用-u-power-充電">使用 U-POWER 充電</h3>
          <p>
            停車後，依 App 或網頁指示點擊「掃描充電」後掃描 QR-Code，或在 App
            上輸入充電槍代碼，即可取下充電槍，插上電動車開始使用；結束充電時，依指示點擊「結束充電」。
            充電完成後請盡速離開，任何故意或重複違規停留的車主，除收取佔用費，將採取嚴肅措施處理，情節嚴重及累犯者將予以停權處分，以確保充電站的順利運作和所有車主的權益。
          </p>
          <h3 id="u-power-充電站收費方式">U-POWER 充電站收費方式</h3>
          <p>
            本充電服務付款方式包含「App
            設定信用卡」支付以及「充電卡」感應。信用卡僅能以有效驗證之本人信用卡透過第三方金流服務商「藍新科技股份有限公司」進行付費。在本服務中會員所輸入信用卡資訊，均由該第三方金流服務商提供並管理之，與本公司無涉。為保護使用者的隱私以及個人資料安全，本公司及本
            App 均不會直接儲存信用卡卡號資訊。
          </p>
          <h4 id="充電與收費方式：">充電與收費方式：</h4>
          <ul>
            <li>
              已通過手機號碼驗證的會員使用信用卡付款時，可採用「先充後付」的方式進行充電。
            </li>
            <li>
              訪客身分或未完成行動電話號碼認證的會員使用信用卡付款時，則需以「先付後充」的方式進行充電，系統將先向發卡銀行取得預授權金
              NT1,350
              元以確認為有效信用卡。您可能會收到發卡銀行的簡訊通知，但此時不會進行扣款，實際支付金額將以實際充電電量計算收取費用，不會超收，敬請放心。
            </li>
            <li>
              若您的充電記錄中有未付款的充電服務，即表示透過付款方式進行的付款交易未成功，請注意，您須先完成付款，才能啟動新的充電。如果
              U-POWER
              收取款項時被您的付款方式拒絕，您將暫時無法使用本充電服務。如果您預設的付款方式失敗，請檢查其信用卡是否在有效期限內、以及信用餘額是否足夠，或請新增付款方式並重試。如您仍然遇到問題，請聯絡您的信用卡銀行，確認您的付款方式是否有效。
            </li>
            <li>
              您瞭解並同意，您使用本充電服務的費用均將以新臺幣結付，如有任何小數點後之金額，應四捨五入進位至整數。
            </li>
          </ul>
          <p>
            <strong>什麼是預授權金</strong
            >：當您開始充電時，我們會向發卡銀行請求一筆預先授權金，預先授權包含了預估的充電費用，此為驗證您的付款方式是否有效，並不會從您的帳戶扣款，當充電完成後我們才會依照實際充電電量計算收取費用，並從您信用卡帳戶扣款。此機制常見於飯店業、租賃業。請注意，若信用卡中的餘額不足以檢驗付款方式是否有效，可能無法進行充電。
          </p>
          <h3 id="會員責任與義務">會員責任與義務</h3>
          <ul>
            <li>
              真實資料義務：您同意於註冊時填寫您正確、完整且符合真實之個人資料；本公司對於會員註冊本
              App 服務之申請，保留是否接受的權利。
            </li>
            <li>
              對您所填寫個人資料如有重複、冒用他人名義、違反公序良俗、或有侵害他人姓名權、公司名稱、商標或其他智慧財產權之虞、或有造成他人誤解之虞、或涉有其他違反法令之嫌、或破壞本
              App
              服務宗旨者，本公司得拒絕您的註冊、或要求您更正為正確、合法、妥適之資料，並保留取消您的會員資格及採取相關措施之權力。如因虛偽不實造成對本公司或第三人造成任何損害者，會員應自行負責。
            </li>
            <li>
              於您就使用本 App
              服務所取得的帳號，您必須負妥善保管和保密的義務，且不得以有償或無償之方式透露或提供予任何第三人登錄或使用，任何經由輸入該組帳號所發生的交易，均視為註冊該帳號之會員之行為，經由該組帳號所為的所有行為及所產生之消費，應由註冊該帳號之會員自負其責任。
            </li>
            <li>
              本公司對您的帳號有無遭盜用並不負查證義務，如果您發現或懷疑您的帳號被其他人冒用或不當使用，應立即通知本公司，以便本公司及時採取適當的因應措施；但上述因應措施不得解釋為本公司因此而明示或默示對您負有任何形式之賠償或補償義務。
            </li>
            <li>
              自會員帳戶註銷、終止或刪除時起，會員於本服務的所有使用權均歸消滅。
            </li>
          </ul>
          <h3 id="個人資料之保護">個人資料之保護</h3>
          <p>
            在下列情況下，本公司必要時得暫停該會員之帳號、存取權限、及對該會員暫停提供本
            App 服務之全部或一部：
          </p>
          <ul>
            <li>
              為執行本約定條款、或使用者有違反本約定條款或其他約定事項或侵害第三人權益之虞為維護本
              App 服務系統之安全或經營者之合法權益。
            </li>
            <li>為保護其他使用者或其他第三人的合法權益。</li>
            <li>為維護本公司及其關聯公司的合法權益。</li>
            <li>為維護本 App 服務系統的正常運作。</li>
          </ul>
          <h3 id="通知">通知</h3>
          <p>
            如依法或其他相關規定須為通知時，本公司得以包括但不限於：電子郵件、一般信件、簡訊、多媒體簡訊、文字訊息、張貼公告於本服務，或以其他現在或未來合理之方式通知您，包括本服務條款之變更。但若您違反本服務條款，以未經授權的方式存取本服務，您將不會收到前述通知，且此時本公司亦無通知您的義務。當您經由授權的方式存取本服務，而同意本服務條款時，您即同意本公司所為之任何及所有給您的通知都視為送達。
          </p>
          <h3 id="u-point-說明">U-POINT 說明</h3>
          <p>
            U-POINT 為旭電馳科研股份有限公司發行之點數業務，點數可直接折抵
            U-POWER 充電服務費用，每10 點 U-POINT可折抵1度電
            (1kWh)，每次充電可在 U-POWER App 選擇是否折抵。
          </p>
          <h4 id="如何取得-u-point">如何取得 U-POINT</h4>
          <ul>
            <li>
              透過參與 U-POWER 之行銷活動 ，會員有機會購得 U-POINT
              方案或免費取得 U-POINT。
            </li>
            <li>
              因透過與 U-POWER 合作之車廠或企業，消費者可因滿足合約條件而取得
              U-POINT。
            </li>
          </ul>
          <h4 id="u-point-使用方法">U-POINT 使用方法</h4>
          <ul>
            <li>折抵消費：每 10 點 U-POINT 點數可折抵 1 度電 (1kWh)。</li>
            <li>查看點數：可由 U-POWER App 查看點數的使用及獲得紀錄。</li>
          </ul>
          <h4 id="u-point-使用限制">U-POINT 使用限制</h4>
          <ul>
            <li>
              U-POWER 發行之 U-POINT
              只能用於充電服務費折抵，不可轉讓，亦不可兌換為同等現金。
            </li>
            <li>依照各合作車廠或企業之合約規範，U-POINT 有其使用期限。</li>
          </ul>
          <h4 id="資料授權">資料授權</h4>
          <p>
            您同意本公司得將您的姓名、電話、地址、消費時間、消費明細、點數資料等，提供各合作廠商，作為合作廠商累兌點作業、點數餘額通知、商品或服務之提供、統計調查分析、交叉比對、行銷與其他服務之使用。
          </p>
          <h4 id="本公司保留權利">本公司保留權利</h4>
          <p>
            本公司保留修改 U-POINT
            發送、使用規則及終止點數相關機制、活動的權利。相關變更將公布於本公司之官方網站。變更後，如您仍繼續使用
            U-POINT 服務，視同同意該變更。
          </p>
          <h3 id="兌換碼">兌換碼</h3>
          <p>兌換碼兌換之 U-POINT 僅可使用於 U-POWER 充電服務費折抵。</p>
          <p>
            兌換碼僅可使用一次，兌換後無法退款或轉移。若不慎遺失或超過兌換期限，恕不補發。完成兌換後，點數將自動發送至您的
            U-POWER 會員帳號中。
          </p>
          <h3 id="使用-u-power-的自動充電（autocharge）">
            使用 U-POWER 的自動充電（Autocharge）
          </h3>
          <p>
            首次啟用自動充電功能需完成註冊 U-POWER 會員帳號，並使用 U-POWER App
            開始充電，您可以在 App
            的充電開始頁面啟用自動充電，當您依系統指示將充電槍連接車輛，我們會將您車輛的識別標識碼
            MAC 地址（EVCC ID）與您的會員帳號相關聯。
          </p>
          <p>
            完成首次啟用，之後充電只需將充電槍置入車輛充電孔，即可啟動充電，並且安全地驗證與您帳戶相關聯的付款方式，省去車輛及付款方式的驗證時間，大大提高充電成功率，啟動充電更快、更方便。
          </p>
          <h4 id="啟用自動充電：">啟用自動充電：</h4>
          <ul>
            <li>從 Apple App Store 或 Google Play Store 下載 U-POWER App。</li>
            <li>
              註冊 U-POWER 會員帳號，完成手機及 Email
              驗證，並確認付款方式使用驗證完成的信用卡。
            </li>
            <li>
              使用 U-POWER App 在任何一個充電站掃描充電槍 QR-Code，在 App
              按下啟用自動充電。
            </li>
            <li>確認您的車輛非租賃或臨時使用，以避免之後產生非預期的帳單。</li>
            <li>
              按下開始充電後，依 App
              提示將充電槍連接車端充電口，等待車輛辨識成功即完成啟用。
            </li>
            <li>當您將車輛綁定成功，我們將向您發送啟用成功的電子郵件。</li>
          </ul>
          <h4 id="使用自動充電：">使用自動充電：</h4>
          <ul>
            <li>
              使用自動充電開始充電：
              <ul class="child-ul">
                <li>
                  當您的車輛啟用自動充電成功並為開啟的狀態，只要您將充電槍置入車輛充電孔，即會自動啟動充電流程，無需其它步驟。
                </li>
                <li>
                  您可隨時從 U-POWER App
                  掌握您的充電進度，在充電開始及結束時系統將推送通知。
                </li>
              </ul>
            </li>
            <li>
              使用自動充電如何「停止充電」：
              <ul class="child-ul">
                <li>
                  透過 App 操作停止充電；點擊「充電進度」按鈕後，再點擊「STOP」
                  即可停止充電。
                </li>
                <li>從車內停止充電；依各家車廠操作不同，請參閱原廠手冊。</li>
                <li>若您有設定電量通知，達到預定電量後系統即自動停止。</li>
              </ul>
            </li>
            <li>
              管理自動充電：
              <ul class="child-ul">
                <li>
                  關閉自動充電：您可從 U-POWER App
                  「車輛設定」管理您的自動充電，當您的車輛被其他人使用時，使用
                  App 可關閉自動充電，避免產生非預期的充電費用。
                </li>
                <li>
                  移除自動充電：您可從 U-POWER App
                  「車輛設定」移除車輛，該綁定之自動充電功能亦將解除。下次當您啟用需重新綁定。
                </li>
              </ul>
            </li>
            <li>
              請注意，已綁定的車輛轉賣過戶，或借給他人，或車輛為租賃、臨時擁有等，車主需負管理之責，先行關閉自動充電功能或刪除該車輛綁定，其衍生的充電費用由車主自行承擔。
            </li>
            <li>
              請勿使用任何品牌之充電轉接頭啟用自動充電功能，若因使用轉接頭綁定所產生的款項爭議，車主需自行承擔。
            </li>
            <li>
              某些車輛可能尚未支援自動充電（Autocharge），請使用充電代碼或掃描
              QR-CODE 重新啟動充電。
            </li>
          </ul>
          <h3 id="交易行為與信用卡交易">交易行為與信用卡交易</h3>
          <ul>
            <li>
              會員使用本
              App，僅能以有效驗證之本人信用卡透過第三方金流服務商「藍新科技股份有限公司」以第三方支付之方式進行付費。在本服務中會員所輸入信用卡資訊之頁面，均由該第三方金流服務商提供並管理之，與本公司無涉。為保護使用者的隱私以及個人資料安全，本公司及本
              App 均不會直接儲存信用卡卡號資訊。
            </li>
            <li>
              當您以信用卡作為付款方式時，即表示您授權本公司逕以您的名義處理信用卡或簽帳金融卡以供付款，並同意為本公司保留相當於到期應付費用之總額。若您對於該等費用有任何疑義，請您透過客服信箱
              <a
                href="mailto:&#99;&#x73;&#x40;&#117;&#x2d;&#112;&#111;&#x77;&#101;&#x72;&#x2e;&#x63;&#x6f;&#x6d;&#x2e;&#116;&#x77;"
                >&#99;&#x73;&#x40;&#117;&#x2d;&#112;&#111;&#x77;&#101;&#x72;&#x2e;&#x63;&#x6f;&#x6d;&#x2e;&#116;&#x77;</a
              >
              或客服電話 0809-080981
              聯絡我們。若本公司司自您所綁定的信用卡或簽帳金融卡無法取得應付消費金額之足額授權，本公司有權暫停提供本服務予您的帳號，並得終止您的使用者帳號，不另行通知。
            </li>
            <li>
              因任何事由而發生支付錯誤或付款失敗之情況時（包括但不限於：信用卡或帳號等資訊遭盜用，或其他軟硬體之技術問題造成訂單不應產生卻完成支付流程等），或是會員因卡片信用額度不足、更換卡片、卡片遺失、第三方支付帳戶失效等，本公司得協助會員進行更正，並提供必要之協助，且得視具體情況先行停止交易或凍結會員帳號之使用，惟本公司不負任何賠償或退費之責任。
            </li>
            <li>
              您瞭解並同意，所有因您使用本服務所生或與之相關的費用，均將以新臺幣結付，如有任何小數點後之金額，應四捨五入進位至整數。
            </li>
            <li>
              本 App
              所提供之優惠方案或折扣，依照本公司系統判定為準；若因任何因素（不可抗力除外）使得優惠無法使用，本公司概不負責。
            </li>
            <li>
              因任何事由而發生欠款狀況（包括但不限於：軟硬體之技術問題造成等），經本公司查證會員確實有充電行為時，您了解並同意，若未完成欠款費用的支付，本公司將暫停會員的服務使用權利，待您完成該費用的結清才能進行後續的服務使用。
            </li>
          </ul>
          <h3 id="電子發票">電子發票</h3>
          <ul>
            <li>
              以信用卡支付充電服務費於每次扣款成功後將開立電子發票，系統會於 24
              小時之內寄送發票開立予您於本 App 所綁定之信箱。
            </li>
            <li>
              電子發票共有「一般發票」、「捐贈發票」、「手機載具」及「報帳用發票三聯式」四種型式。
            </li>
            <li>
              若您有中獎發票，系統將寄送中獎通知，您可至「7-Eleven統一便利超商」iBon
              機台自行列印中獎發票。
            </li>
            <li>
              依統一發票使用辦法規定，發票一經開立，個人戶發票無法換開為公司戶發票；公司戶發票無法換開為個人戶發票。請於消費時確認應取得之發票類型，本公司已盡告知義務，且為配合國稅局勸止二聯換開三聯之政策，本公司有權考量各因素後拒絕換開發票。收到發票三日內，若資料有誤，需更改抬頭名稱或統一編號，請聯繫聯繫客服人員
              <a
                href="mailto:&#x63;&#115;&#64;&#117;&#45;&#x70;&#111;&#119;&#101;&#114;&#x2e;&#99;&#111;&#x6d;&#46;&#116;&#x77;"
                >&#x63;&#115;&#64;&#117;&#45;&#x70;&#111;&#119;&#101;&#114;&#x2e;&#99;&#111;&#x6d;&#46;&#116;&#x77;</a
              >（以收信時間為憑，逾期不受理），註明原發票號碼、要修改的抬頭、統編及發票寄送
              Email
              地址，客服人員將會盡速為您處理。以利為您換開發票，一般處理時間需約一週
              （不含假日）。
            </li>
          </ul>
          <h3 id="充電注意事項">充電注意事項</h3>
          <ul>
            <li>
              本服務係採無人化經營模式，為確保自身安全，當您使用本服務時，應依照本公司說明及指示，檢查充電樁是否可正常使用，若充電樁有任何故障或其他異常情事，您應避免使用或立即停止使用，並立即聯絡客服人員，本公司將保留減免該次使用相關費用（包括但不限於充電費）之權利。
            </li>
            <li>
              當您使用本服務使用充電樁時，您應當遵守中華民國（台灣）相關法令、道路交通安全規則之慣例。
            </li>
            <li>
              當您使用本服務使用充電樁時，設備僅限您本人自行使用，您不得交由任何第三人操作，亦不得利用本服務操作違禁品、危險品、不潔或易於汙損充電樁之物品或其他可能影響使用安全之物品，更不得利用本服務從事任何侵害他人權益或違法之行為。
            </li>
            <li>
              在您使用本服務期間內，若發生任何違反道路交通管理事件，本公司將檢附相關事證依法逕行舉發，因此所生及與之相關的罰鍰其他必要費用等，概由您自行負擔。
            </li>
            <li>
              當您返還所使用的充電樁時，應自行留意有無遺落個人物品。本公司對於會員所遺落之個人物品，不負保管責任。
            </li>
            <li>
              當您使用本服務使用充電樁時，您應遵循下列使用原則：
              <ul class="child-ul">
                <li>嚴禁將本公司充電樁使用於非本公司所認可之用途。</li>
                <li>
                  請保持本公司充電槍連接埠潔淨無異物，嚴禁使用金屬物接觸或造成本公司充電樁短路。
                </li>
                <li>
                  使用完本公司充電樁後，請確實將充電槍頭掛回專用充電槍保護座。
                </li>
                <li>
                  嚴禁將本公司充電樁投入火中、加熱、降溫、浸水、摔落或敲擊，及進行任何試圖拆解或破壞之行為。
                </li>
                <li>嚴禁自行拆卸或改變本公司充電樁外型、結構。</li>
                <li>
                  嚴禁使用非本公司官方所認可之方式，自行對本公司充電樁充放電。
                </li>
                <li>嚴禁使用任何溶劑或化學藥劑於本公司充電樁。</li>
                <li>
                  請勿將任何物品儲藏或堆放於本公司充電樁下方與本公司充電樁上蓋間之空間。
                </li>
                <li>
                  嚴禁以任何方式或技術，包括但不限於黏貼、彩繪或雷射雕刻等，暫時或永久性改變本公司充電樁之外型或外觀。
                </li>
                <li>
                  嚴禁丟棄本公司充電樁，故障之本公司充電樁請聯絡本公司客服人員（客服電話：0809-080981）協助處理。
                </li>
                <li>
                  嚴禁複製、改造或公開傳輸本公司為提供本服務所使用之軟體、程式、資料庫及應用程式介面（API）（但不包括因使用本服務所需之公開傳輸），及使用逆向工程、拆解或其他方法以分析上開軟體、程式、資料庫及應用程式介面（API）。
                </li>
              </ul>
            </li>
            <li>
              謹提醒使用者於保管本公司充電樁時，注意下列事項：
              <ul class="child-ul">
                <li>
                  本公司充電樁在使用後可能會有些微發熱狀況，進行充電操作或拿取時請加以注意。
                </li>
                <li>
                  本公司得隨時修改或增補本公司充電樁保管規則之權利，並將以公告或單獨的方式通知您。
                </li>
                <li>
                  若因可歸責於您的事由，導致充電樁、或配件汙損、損壞或遺失，您同意本公司依照原價向您收取維修費用、清潔費、重製工本費或營業損失等。
                </li>
                <li>
                  非經本公司事前書面同意，您不得將充電樁使用於行銷、廣告、宣傳、影劇拍攝、產品展示等商業性之用途。
                </li>
              </ul>
            </li>
          </ul>
          <h3 id="不可抗力暫停提供服務">不可抗力暫停提供服務</h3>
          <p>
            因任何超出本公司所能合理預測或控制之事件，包括但不限於地震、暴風雨、颱風、水災、其他自然災害、火災、爆炸、暴動、戰爭、恐怖活動、傳染病、罷工或其他勞工爭議、法令變更或其他政府行動等，導致本公司不能或遲延履行本條款所定任何義務（包括但不限於本公司服務暫停、中斷或無法正常運作）時，本公司將於本
            App
            公告原因及預計恢復時間，本公司就因此所生或與之相關的一切損害（失）皆不負任何賠（補）償責任。
          </p>
          <h3 id="終止">終止</h3>
          <p>
            若您使用本服務使用電動車充電站時，有任何違反本條款或其他法令之情形，本公司有權暫停提供本公司服務予您的帳號，並得終止您的使用者帳號，不待另行通知，您不得就此向本公司主張任何賠（補）償。本公司依照本條款規定終止您的使用者帳號或停止提供本公司服務之全部或一部時，並不影響本公司所得向您主張之任何權利及賠（補）償。
          </p>
          <h3 id="保險">保險</h3>
          <p>
            本服務提供的充電樁製造商飛宏全設備均已投保1200萬美元產品責任險、旭電馳科研已為全站點投保公共意外險。若您有其他相關需求，應自行投保。
          </p>
          <h3 id="智慧財產權保護">智慧財產權保護</h3>
          <p>
            本公司所使用之軟體、程式、網站及 App
            上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、App
            架構、網站畫面的安排、網頁設計、會員內容等，均由本公司或其他依法有權利人所擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、公開傳輸、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。如有違反，除依著作權法及相關法律規定論處，並應對本公司負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
          </p>
          <h3 id="第三方連結">第三方連結</h3>
          <p>
            若本公司服務包含連接至其他網站或第三方所提供之服務或網路資源的連結（下稱「第三方連結」），該等連結僅供您方便所設，不得解釋為本公司與該等網站、服務或網路資源之所有者或經營者間存有任何關聯，該等網站、服務或網路資源之內容亦非本公司所得控制，本公司不為第三方連結或其內容之功能性、正確性、合法性或合適性等背書，若您連接至該等網站、服務或網路資源，應自行承擔一切風險，本公司就您因使用該等網站、服務或網路資源所生或與之相關的一切損害（失）皆不負任何賠（補）償責任。
          </p>
          <h3 id="免責聲明">免責聲明</h3>
          <ul>
            <li>
              會員因使用本 App
              服務所受之直接或間接損害，倘若依契約約定或依相關法令規定應由本公司負賠償責任，本公司對於任一會員之賠償責任，以本公司對該會員就損害當次交易所實際收取之費用數額為上限。
            </li>
            <li>
              本服務僅供會員進行車輛充電為目的，並不負任何車輛及財務保管責任。
            </li>
            <li>
              若因您的過失導致的任何損失應由您自行承擔，該過失包括但不限於：不遵守交通規則；不遵守本服務地區管理規範；未及時進行交易操作；您使用的電腦或手機被他人侵入；未按正常操作流程操控充電樁及周邊設備；或遭拖吊或遭處罰鍰等，該損失皆與本公司無涉。
            </li>
            <li>
              會員將車輛駛入充電場域內，應遵守限速於15公里內之場內規範，以確保站場內之人車安全。如無遵守限速及交通規則於站點內有交通意外或碰撞，且可歸因於會員或駕駛者，所造成之人身或財產之損失，應由會員或駕駛者自行負擔責任，與本公司無涉。
            </li>
            <li>
              會員應遵守停放車輛之規定，包含但不限於進出場內外之標誌、標線，以及車輛停放格位的標誌、標線、坡面落差等，且本公司不保證可使用車位之現況與本軟體所示之圖片相符，會員應自行斟酌使用本服務，如致車輛損壞，本公司概不負責。
            </li>
          </ul>
          <h3 id="u-power-充電站皆通過充電樁的檢測標準">
            U-POWER 充電站皆通過充電樁的檢測標準
          </h3>
          <p>
            U-POWER
            充電站整合不同合作的充電設備以及充電站營運商使用的充電設備。旭電馳科研在
            U-POWER
            充電車位施工與維護的同時，會進行充電樁的檢測、認證和保固等確認，並完成安心充電的檢測紀錄，確保
            U-POWER 充電車位的使用和充電成功。
          </p>
          <h3 id="u-power-充電站設備安全與損毀">
            U-POWER 充電站設備安全與損毀
          </h3>
          <p>
            若充電站相關充電與停車之設備，因人為造成損毀或損壞，本公司將依現場監視系統協助確認肇事過程。若因會員故意、過失或不慎操作致本公司受有損害，本公司得向其請求損害賠償。
          </p>
          <h4 id="責任範圍">責任範圍</h4>
          <p>
            於法律許可的最大範圍內，本公司對於您任何間接性、附隨性、偶發性或懲罰性損害（失）皆不負賠（補）償責任，無論該等損害（失）係基於契約、違反擔保、侵權（含過失）或其他法律依據，即便本公司曾被告知該等損害（失）存在或發生之可能性時亦同。
          </p>
          <p>
            於法律許可的最大範圍內，就因您使用本公司服務所生或與之相關的一切損害（失），本公司對您所負的責任僅以本公司服務所收取之費用為限，無論該等損害（失）係基於契約、違反擔保、侵權（含過失）或其他法律依據。即便存在數個因您使用本公司服務所生或與之相關的爭訟時，亦不會因此增加或擴張前述賠償金額上限。
          </p>
          <h4 id="損害填補">損害填補</h4>
          <p>
            若因您違反本條款或不當使用本公司服務而造成任何第三人死亡、人身或財產上損害（失），您同意賠（補）償並使本公司及其董事、員工、代理人、承包商、供應商、商業夥伴免於任何因此所生或與之相關的請求、損害（失）、罰款、成本及費用（包括但不限於合理之律師費用）。您同意在合理要求的情況下，協助本公司及其董事、員工、代理人、承包商、供應商、商業夥伴就任何因您違反本條款或不當使用本公司服務而招致之爭訟。
          </p>
          <h3 id="客服資訊">客服資訊</h3>
          <p>
            您在使用 U-POWER
            充電期間，若遇到充電樁無法使用、或費用計算錯誤等問題，您可於 App
            操作「啟用充電」的介面中，點擊「聯繫客服」或撥打 02-2767-1885
            向旭電馳科研的客戶服務團隊聯繫。聯繫客服時，請您依遵循下步驟進行服務：
          </p>
          <ul>
            <li>
              提供客服專員您的問題類型：「充電設備問題」、「帳單錯誤問題」或其他等。
            </li>
            <li>
              承上，若為充電設備問題，請提供您的所在充電站名稱、充電槍編號。若充電設備為能正確連線或客服專員無法取得充電站的狀態資訊，客服專員將與您一起遠端確認現場環境、充電設備或車位狀況後，協助評估與解難。
            </li>
            <li>
              若為帳單問題，請提供您的帳單編號，客服專員將直接遠端確認，並協助您安排退款與後續。
            </li>
          </ul>
          <h3 id="法令適用與管轄法院">法令適用與管轄法院</h3>
          <p>
            您同意因本條款或因您使用 U-POWER
            服務所生或與之相關的一切爭議或糾紛，皆以中華民國（台灣）法律為準據法。就您與
            U-POWER 間因本條款或因您使用 U-POWER
            服務與之相關的一切爭議或糾紛，您同意先與 U-POWER
            依友好協商方式解決該等爭議或糾紛；若雙方未能於 60
            日內依友好協商方式解決該等爭議或糾紛時，您或 U-POWER
            任一方皆得就該等爭議或糾紛向臺灣臺北地方法院聲請調解；若調解未能成立，雙方同意以臺灣臺北地方法院為第一審管轄法院。雙方同意，在該等爭議或糾紛未能依照本條所定程序予以解決時，不得採取任何其他紛爭解決方式。
          </p>
          <h3 id="其他未盡事宜">其他未盡事宜</h3>
          <p>
            使用者茲同意本條款未盡事宜，悉依本公司於網站中之相關規定及說明辦理。
          </p>
          <h3 id="服務內容公告及變動">服務內容公告及變動</h3>
          <p>
            本公司有權隨時修改本條款及服務內容，於透過相關網頁公告後、會員仍繼續使用本軟體時即視為生效。
          </p>
        </article>
        <p class="last-update">最後更新日期：2024 年 08 月 09 日</p>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Service",
};
</script>
